<!--
  PACKAGE_NAME : src/pages/euc/device/server
  FILE_NAME : cisco-server.vue
  AUTHOR : jhcho
  DATE : 2024-04-16
  DESCRIPTION :
-->
<template>
  <div class="container">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <Tab title="CM 관리">
        <div class="locker_setting_list sub_new_style01 sub_ui_box1" v-if="tabIndex === 0">
          <div class="cusmain-table-wrap">
            <CmList ref="cmRef" />
          </div>
        </div>
      </Tab>
    </Tabs>
  </div>
</template>
<script>
import Tabs from "@/components/common/tabs.vue";
import CmList from "@/pages/euc/device/server/cmList.vue";
import Tab from "@/components/common/tab.vue";

export default {
  components: { Tab, CmList, Tabs },
  data() {
    return {
      tabIndex: 0,
    }
  },
  methods: {
    async tabSelectedIndex(index) {
      // cm data 변경 시 aes, svr에서 반영할 수 있도록 하기 위해 v-if 사용
      this.tabIndex = index;
    },
  },
  mounted() {
  },
  created() {
  }
}
</script>
<style scoped>

</style>